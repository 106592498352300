.mainHeader {
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100px;
  background: #fff;
  border-bottom: 2px solid #f1f2f3;

  &::before {
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s linear;
    width: 100vw;
    height: 0vh;
    opacity: 0;
  }
  &.open {
    &::before {
      opacity: 1;
      height: 100vh;
    }
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    background: #fff;
    @media (max-width: 960px) {
      padding: 0 16px;
    }
  }

  h1 {
    font-size: 30px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }

  .nav {
    flex: 1;
    // margin: 0 90px 0 80px;

    ul {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: flex-start;
    }

    a {
      display: flex;
      align-items: center;
      padding-right: 10px;
    }

    img {
      margin-right: 10px;
    }

    a {
      span {
        position: relative;
        transition: color 0.4s;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    button {
      margin: 0 5px;
    }
  }

  .link {
    line-height: 1.1;
  }
  .linkIcon {
    transform: rotate(90deg) scaleY(2);
  }
  @media (max-width: 960px) {
    .burger {
      cursor: pointer;
      .line {
        transform-origin: center center;
        transition: all 0.4s;
      }
    }
    &.open {
      .burger {
        .line {
          &:nth-child(1) {
            transform: translateY(6px) translateX(-5px) rotate(45deg);
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
          }
          &:nth-child(3) {
            opacity: 0;
            transform: translateY(25%);
          }
        }
      }
      .menuContainer {
        transform: translateY(0);
      }
    }
    .menuContainer {
      position: fixed;
      left: 0;
      z-index: -1;
      top: 72px;
      width: 100%;
      // bottom: 0;
      background: #fff;
      flex-direction: column;
      justify-content: flex-start;
      transform: translateY(calc(-100% - 72px));
      transition: all 0.5s;

      nav {
        flex: inherit;
        flex: initial;
        width: 100%;
        flex: 1;
        padding: 0 20px;
        margin: 0 20px;
        ul {
          flex-direction: column;
          // margin-bottom: 80px;
          margin: 0;
          li {
            padding: 20px 0;
            width: 100%;
            a {
              justify-content: center;
            }
          }
        }
      }
      .buttonsContainer {
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        padding: 20px;
        & > div {
          margin: 0;
        }
        button {
          margin: 5px 0;
        }
      }
    }
  }
}

.nav__menu_item {
  margin-right: 20px;
  .link {
    transition: 1s ease-in-out;
    border-bottom: 2px solid transparent;
    padding: 10px 30px;
  }
  .linkSpan {
    transition: 1s ease-in-out;
    border-bottom: 2px solid transparent;
    padding: 10px;
  }
  ul.nav__submenu {
    transition: 0.3s ease-in-out;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    width: 100%;
    a {
      padding: 10px 20px;
    }
  }
}
.nav__menu_item:hover {
  .link {
    border-bottom: 2px solid red;
  }
  ul.nav__submenu {
    opacity: 1;
    transform: scaleY(1);
  }
}

.nav__menu_item {
  display: inline-block;
  position: relative;
}
.nav__submenu {
  text-transform: none;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}
.nav__submenu_item {
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}
